/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* -------------------------------------------------------------------------- */
/*                                Helper Class                                */
/* -------------------------------------------------------------------------- */

/**
 * Utility helper class for various helper functions.
 */
class UtilsHelper {
  /**
   * Converts pixel value to rem value.
   * @param {number} pxValue - The pixel value to be converted.
   * @returns {string} The converted value in rem.
   */
  static toRem(pxValue) {
    return `${pxValue / 16}rem`;
  }

  /**
   * Reverses the key-value mapping of an object.
   * @param {object} object - The object to be reversed.
   * @returns {object} The reversed object.
   */
  static reverseMap(object) {
    const reversed = {};
    for (const key in object) {
      reversed[UtilsHelper.capitalize(object[key])] = key;
    }
    return reversed;
  }

  /**
   * Capitalizes the first letter of a string.
   * @param {string} string - The string to be capitalized.
   * @returns {string} The capitalized string.
   */
  static capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Removes accets from a string
   * @param {string} str
   * @returns string
   */
  static removeAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}

export default UtilsHelper;
